<template>
  <div id="index" class="index">
    <IndexHeader></IndexHeader>
    <router-view></router-view>
    <IndexFotter></IndexFotter>
  </div>
</template>

<script>
import IndexHeader from "@/components/IndexHeader.vue";
import IndexFotter from "@/components/IndexFotter.vue";
export default {
  name: "index",
  components: {
    IndexHeader,
    IndexFotter,
  },
};
</script>

<style>
* {
  font-family: "SourceHanSansCN";
}
@font-face {
  font-family: "SourceHanSansCN";
  src: url("../assets/font/SourceHanSansCN-Heavy.otf");
}
@font-face {
  font-family: "WenQuanYi";
  src: url("../assets/font/文泉驿等宽微米黑（WenQuanYi\ Micro\ Hei\ Mono）.ttf");
}
</style>