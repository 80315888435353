import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta:{
      title: '邱云网络',
      keepAlive: true, // 需要被缓存
    },
    children:[
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/Index/Home.vue'),
        meta:{
          title: '邱云网络',
          keepAlive: true, // 需要被缓存
        }
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/Index/About.vue')
      },
      {
        path: '/contract',
        name: 'Contract',
        component: () => import('../views/Index/Contract.vue')
      }
    ]
  },
  
]

const router = new VueRouter({
  routes,
  mode:'history'
})

export default router
