import Vue from 'vue'
import Index from './App.vue'
import router from './router'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(Index)
}).$mount('#app')


router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined?'邱云网络':to.meta.title
  
  next();
})