<template>
  <div>
    <footer>
      ©2020 QiuCloud 版权所有 &nbsp;<a href="https://beian.miit.gov.cn"
        >津ICP备2021000430号-1</a
      >
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style>
a {
  text-decoration: none;
}
a:hover {
  color: rgb(31, 169, 219);
}
footer {
  font-size: 14px;
  display: flex;
  height: 100px;
  width: 100%;
  color: #fff;
  color: #999;
  line-height: 100px;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
  justify-self: center;
  justify-items: center;
}
footer > a {
  font-family: "SourceHanSansCN";
  color: #999;
}
</style>