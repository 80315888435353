<template>
  <header>
        <div class="head">
            <div>
                <router-link to="/" class="logo"></router-link>
                <div class="tag">
                    <router-link class="tags" to="/about">关于我们</router-link>
                    <router-link class="tags" to="/contract">联系我们</router-link>
                </div>
            </div>
            
        </div>
        <div class="title">邱生活，邱上云</div>
        <div class="content">大“邱”庄生活服务平台</div>
    </header>
</template>

<script>
export default {
    
}
</script>

<style>
        .tags{
            text-decoration:none;
        }
        .tags:hover{
            color:  rgb(31,169,219);
        }

        .router-link-active,.router-link-exact-active{
            color:  rgb(31,169,219)!important;
        }
        header{
            height: 800px;
            width: 100%;
            background-image: url(../assets/banner.png);
            background-size:cover;
            background-position: center;
            position: relative;
        }

        .head{

            display: flex;
            justify-content: center;
            background: #fff;
            height: 80px;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
        }
        .head>div{
            position: relative;
            max-width: 1920px;
            min-width: 1280px;
            display: flex;
            flex-direction: row;
        }

        .title{
            padding-top: 270px;
            font-size: 150px;
            color: #fff;
            text-align: center;
        }
        .content{
            color: #fff;
            font-size: 40px;
            font-weight: bold;
            width: 100%;
            margin-top: 40px;
            text-align: center;
        }
        .logo{
            height: 80px;
            width: 200px;
            background-image: url(../assets/logo.png);
            background-size:cover;
            background-position: center;
        }

        .tag{
            display: flex;
            position: absolute;
            float: right;
            right: 0;
            
        }
        .tags{
            height: 80px;
            line-height: 80px;
            font-size: 18px;
            color: #666;
            margin: 0 15px;
        }
</style>